import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import app from '../firebase.js';
import { getDatabase, ref, onValue } from "firebase/database";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import '../styles/rewardsPage.css';
import node1Image from '../assets/cuttyC.png';
import logoImage from '../assets/newAssets/icons/cuttyTopLeft.png';
import node0ActiveImage from '../assets/progressIcons/progress/step 0.png';
import node1ActiveImage from '../assets/progressIcons/progress/step 1.png';
import node2ActiveImage from '../assets/progressIcons/progress/step 2.png';
import node3ActiveImage from '../assets/progressIcons/progress/step 3.png';
import node4ActiveImage from '../assets/progressIcons/progress/complete.png';
import node1Unvisited from '../assets/progressIcons/cuttyNumbers/1/cutty 1 uncompleted.png'
import node1Visited from '../assets/progressIcons/cuttyNumbers/1/cutty 1 completed.png'
import node1Current from '../assets/progressIcons/cuttyNumbers/1/cutty 1 current.png'
import node2Unvisited from '../assets/progressIcons/cuttyNumbers/2/cutty 2 uncompleted.png'
import node2Visited from '../assets/progressIcons/cuttyNumbers/2/cutty 2 completed.png'
import node2Current from '../assets/progressIcons/cuttyNumbers/2/cutty 2 current.png'
import node3Unvisited from '../assets/progressIcons/cuttyNumbers/3/cutty 3 uncompleted.png'
import node3Visited from '../assets/progressIcons/cuttyNumbers/3/cutty 3 completed.png'
import node3Current from '../assets/progressIcons/cuttyNumbers/3/cutty 3 current.png'

function RewardsPage() {
  const [nodeData, setNodeData] = useState({
    node1: "unvisited",
    node2: "unvisited",
    node3: "unvisited",
    points: 0,
  });

  const [activeNodeImage, setActiveNodeImage] = useState(node1ActiveImage);
  const [activeNode1Image, setActiveNode1Image] = useState(node1Unvisited);
  const [activeNode2Image, setActiveNode2Image] = useState(node2Unvisited);
  const [activeNode3Image, setActiveNode3Image] = useState(node3Unvisited);
  const [nodeProgressText, setNodeProgressText] = useState("0%");
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [showPopup, setShowPopup] = useState(false); 
  const [redeemButtonDisabled, setRedeemButtonDisabled] = useState(false);
  const [redeemButtonText, setRedeemButtonText] = useState("Redeem Progress");
  const [redeemErrorText, setRedeemErrorText] = useState("");

  const navigate = useNavigate();

  const handleRedeemClick = () => {
    setShowPopup(true); // Show the popup
  };

  const handleConfirmRedeem = () => {
    setShowPopup(false);
    navigate('/redeem-rewards'); // Proceed to redeem rewards
  };

  const handleClosePopup = () => {
    setShowPopup(false); // Close the popup without redeeming
  };


  useEffect(() => {
    const db = getDatabase();
    const auth = getAuth();
    const userId = auth.currentUser.uid;
    const userRef = ref(db, `users/${userId}/nodes/event1`);
  
    onValue(userRef, (snapshot) => {
      const data = snapshot.val();
      setNodeData(data);
    });
  }, []);

  useEffect(() => {
    // Check the points to determine the reward tier
    const reward = nodeData.reward;
    const points = nodeData.points;

    let isDisabled = false;
    
    if (points === 0 || reward === "tier1" || reward === "tier2" || reward === "tier3") {
      isDisabled = true;
      setRedeemButtonText("Redeem Unavailable");
      setRedeemErrorText("Insufficient nodes visited (must be at least one) or your reward has already been redeemed.");
    }
 
    else{
      isDisabled = false;
      setRedeemButtonText("Redeem Progress");
      setRedeemErrorText("");
    }

    setRedeemButtonDisabled(isDisabled);
  }, [nodeData.reward]);

  useEffect(() => {
    // Function to determine the image based on visitation status
    const determineNodeImage = (status, unvisited, visited, current) => {
      switch(status) {
        case 'unvisited':
          return unvisited;
        case 'visited':
          return visited;
        case 'current':
          return current;
        default:
          return unvisited;
      }
    };

    setActiveNode1Image(determineNodeImage(nodeData.node1, node1Unvisited, node1Visited, node1Current));
    setActiveNode2Image(determineNodeImage(nodeData.node2, node2Unvisited, node2Visited, node2Current));
    setActiveNode3Image(determineNodeImage(nodeData.node3, node3Unvisited, node3Visited, node3Current));

    switch(nodeData.points) {
      case 1:
        setActiveNodeImage(node1ActiveImage);
        setNodeProgressText("33%");
        break;
      case 2:
        setActiveNodeImage(node2ActiveImage);
        setNodeProgressText("66%");
        break;
      case 3:
        setActiveNodeImage(node3ActiveImage);
        setNodeProgressText("100%");
        break;
      default:
        // If points are 0 or not defined, show the first node as active
        setActiveNodeImage(node0ActiveImage);
        setNodeProgressText("0%");
        break;
    }

    // Check if fullyCompleted is true, then set to the completed state
    if (nodeData.fullyCompleted) {
      setActiveNodeImage(node4ActiveImage);
      setNodeProgressText("100%");
    }

  }, [nodeData]);
  
  const getColor = (visitedStatus) => {
    switch(visitedStatus){
      case 'current':
        return '#E23D2A'; 
      case 'unvisited':
        return '#FDA4BA'; 
      case 'visited':
        return '#A8A8A8'; 
    }
  }
  
  return (
    <div className='frameRewards'>
      <div className="menuIcon" onClick={() => setDropdownOpen(!isDropdownOpen)}>
          ☰
          {isDropdownOpen && (
            <div className="dropdownMenu">
              <div onClick={() => navigate('/leaderboard')}>Leaderboard</div>
              <div onClick={() => navigate('/node-rewards')}>Node Rewards</div>
            </div>
          )}
        </div>
      <div className='rewardsBackground'>
      <div className='activeNodeContainer'>
        <img src={activeNodeImage} className="activeNodeImage" alt="Active node" />
        <span className='rewardProgressText'><strong>Reward Progress</strong></span>
        <span className='rewardProgressText2'>You have completed {nodeProgressText} <br/> of your rewards</span>
      </div>
        <div className='containerRewardPage'>
          <img src={logoImage} className="logoImage" alt="Logo" />
          <div className="containerReward">
          <div className="node" style={{borderColor: getColor(nodeData.node1)}}>
              <img src={ activeNode1Image } alt="Node" />
              <span className='locationText'>Goodies Node</span>
              <span className='locationDetailsText'>Cutty Launch Party<br/>Los Angeles, CA</span>
            </div>
            <div className="node" style={{borderColor: getColor(nodeData.node2)}}>
              <img src={ activeNode2Image } alt="Node" />
              <span className='locationText'>Cuties n' Cream Node</span>
              <span className='locationDetailsText'>Cutty Launch Party<br/>Los Angeles, CA</span>
            </div>
            <div className="node" style={{borderColor: getColor(nodeData.node3)}}>
              <img src={ activeNode3Image } alt="Node" />
              <span className='locationText'>The Bar Node</span>
              <span className='locationDetailsText'>Cutty Launch Party<br/>Los Angeles, CA</span>
            </div>

            {showPopup && (
              <div className='confirmationPopup'>
                <div className='popupContent'>
                  <p>Are you sure you want to redeem your progress? This action can only be done once.</p>
                  <button className='redeemConfirm' onClick={handleConfirmRedeem}>Confirm</button>
                  <button className='redeemCancel' onClick={handleClosePopup}>Cancel</button>
                </div>
              </div>
            )}

            {!showPopup && (
              <button 
              className={`redeemButton ${redeemButtonDisabled ? 'disabled' : ''}`}
              onClick={!redeemButtonDisabled ? handleRedeemClick : undefined}
              style={{ backgroundColor: redeemButtonDisabled ? '#a8a8a8' : '' }}
            >
                {redeemButtonText}
              </button>
            )}
            <div className='redeemError'>
              {redeemErrorText}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RewardsPage;

